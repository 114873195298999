exports.components = {
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-discography-js": () => import("./../../../src/pages/discography.js" /* webpackChunkName: "component---src-pages-discography-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-notfound-js": () => import("./../../../src/pages/notfound.js" /* webpackChunkName: "component---src-pages-notfound-js" */),
  "component---src-pages-pastlive-js": () => import("./../../../src/pages/pastlive.js" /* webpackChunkName: "component---src-pages-pastlive-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-discography-js": () => import("./../../../src/templates/discography.js" /* webpackChunkName: "component---src-templates-discography-js" */),
  "component---src-templates-live-js": () => import("./../../../src/templates/live.js" /* webpackChunkName: "component---src-templates-live-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

